<!--  -->
<template>
	<div class="wb-100">
		<div class="p-center dp-fl pd-20 pl-40 .pr-40 index">
			<div class="wb-18">
				<img
					src="../assets/images/logo.png"
					alt=""
					class="wb-100 img-wh"
				/>
			</div>
			<div class="pd-tp wb-50">
				<el-dropdown v-for="(item, index) in toplist" :key="index">
					<span class="el-dropdown-link" @click="title(item)">
						{{ item.title
						}}<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu v-if="item.children" slot="dropdown">
						<el-dropdown-item
							:command="title"
							v-for="(items, indexs) in item.children"
							:key="indexs"
							@click.native="sjym(items)"
							>{{ items.title }}</el-dropdown-item
						>
					</el-dropdown-menu>
				</el-dropdown>
			</div>

			<div class="wb-15 right">
				<el-dropdown
					v-for="(item, index) in rightlist"
					:key="index"
					@click.native="taurles(item)"
				>
					<span class="el-dropdown-link">
						<img
							class="righticon"
							v-if="item.icon"
							src="../assets/images/yuyuan.png"
						/>
						{{ item.title
						}}<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu v-if="item.children" slot="dropdown">
						<el-dropdown-item
							v-for="(items, indexs) in item.children"
							:key="indexs"
							:command="items.title"
							@click.native="taurl(items)"
							>{{ items.title }}</el-dropdown-item
						>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<div class="m-center"></div>
	</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Utilsess from '../../utils'
export default {
	//import引入的组件需要注入到对象中才能使用
	components: {},
	data() {
		//这里存放数据
		return {
			toplist: [
				{ title: '主页' },
				{
					title: '公司',
					children: [
						{ title: '公司概述', id: '1' },
						{ title: '企业文化', id: '2' },
						{ title: '企业荣誉', id: '3' },
						{ title: '企业资质', id: '4' },
						{ title: '专业团队', id: '5' },
						{ title: '我们的服务', id: '6' },
						{ title: '社会责任', id: '7' },
						{ title: '品牌展览及会议', id: '8' },
						{ title: '丞华大事记', id: '9' },
					],
				},
				{
					title: '新闻',
					children: [
						{ title: '公司动态', id: '1' },
						{ title: '展会动态', id: '2' },
						{ title: '媒体聚焦', id: '3' },
						{ title: '丞华｜每日一语', id: '4' },
					],
				},
				{
					title: '展会',
					children: [
						{ title: '近期展会', id: '1' },
						{ title: '近期会议', id: '2' },
						{ title: '2024项目计划', id: '3' },
					],
				},
				{
					title: '服务',
					children: [
						{ title: '展览工程', id: '1' },
						{ title: '商业情报', id: '2' },
						{ title: '旅游景点', id: '3' },
						{ title: '交通指南', id: '4' },
						{ title: '展商服务', id: '5' },
						{ title: '观众服务', id: '6' },
					],
				},
				{ title: '精准对接' },
				{ title: '网上展厅' },
				{
					title: '联系我们',
					children: [
						{ title: '工作机会', id: '1' },
						{ title: '合伙人众创合作平台', id: '2' },
						{ title: '联系我们', id: '3' },
					],
				},
			],
			rightlist: [
				{
					title: '管理后台',
					icon: true,
					children: [
						{ title: '展商服务', url: 'https://com.chinaznzz.com' },
						{ title: '搭建商', url: 'https://bld.chinaznzz.com' },
						{
							title: '会展公司',
							url: 'https://exh.chinaznzz.com',
						},
					],
				},
				{
					title: '近期展会',
				},
			],
		}
	},
	//监听属性 类似于data概念
	computed: {},
	//监控data中的数据变化
	watch: {},
	//方法集合
	methods: {
		title(e) {
			if (e.title != '主页') {
				Utilsess.$emit('demoes', 0)
				this.$router.push('/assemble?title=' + e.title)
			} else {
				this.$router.push('/index')
			}
		},
		taurl(e) {
			// console.log(e)
			window.open(e.url)
		},
		taurles(e) {
			console.log(e)
			// window.open(e.url)
			if (e.title == '近期展会') {
				Utilsess.$emit('demoes', 0)
				this.$router.push('/assembles?title=' + e.title + '&id=' + 1)
			}
		},
		toprouter(e) {
			console.log(e.title)
		},
		sjym(e) {
			console.log(e)
			this.$router.push('/assembles?title=' + e.title + '&id=' + e.id)
			Utilsess.$emit('demoes', 0)
		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {},
	beforeCreate() {}, //生命周期 - 创建之前
	beforeMount() {}, //生命周期 - 挂载之前
	beforeUpdate() {}, //生命周期 - 更新之前
	updated() {}, //生命周期 - 更新之后
	beforeDestroy() {}, //生命周期 - 销毁之前
	destroyed() {}, //生命周期 - 销毁完成
	activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped lang="scss">
.el-icon-arrow-down {
	display: none;
}
.el-dropdown-menu {
	background-color: rgb(47, 93, 135);
}
::v-deep.el-popper[x-placement^='bottom'] .popper__arrow:after {
	border-bottom-color: rgb(47, 93, 135) !important;
}

.el-dropdown-menu__item {
	color: #fff;
}
.el-dropdown-link {
	cursor: pointer;
	font-size: 16px;
	font-weight: bold;
}
.el-dropdown {
	margin-right: 5%;
}
.img-wh {
	height: 85%;
}
.right {
	.el-dropdown:first-child {
		padding: 10px 20px;
		border: 2px solid rgb(8, 29, 76);
		color: rgb(8, 29, 76);
	}
	.el-dropdown:nth-child(2) {
		padding: 10px 20px;
		border: 2px solid rgb(79, 187, 219);
		color: rgb(79, 187, 219);
	}
}
.righticon {
	vertical-align: middle;
}
.index {
	text-align: center;
}
.index {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 3;
	height: 50px;
}
.wb-18 {
	height: 100%;
}
</style>
