<template>
	<div id="app bg-c">
		<webTop />
		<router-view :key="key" class="pages"></router-view>
		<Foot />
	</div>
</template>
<script>
import Cookies from 'js-cookie'
import webTop from './components/webTop.vue'
import Foot from './components/Foot.vue'
import Utilsess from '../utils'

export default {
	components: { webTop, Foot },
	data() {
		return {
			key: 0,
		}
	},
	mounted() {
		var that = this

		Utilsess.$on('demoes', function (e) {
			that.key++
		})
	},
	methods: {},
}
</script>

<style lang="less">
.pages {
	margin-top: 90px;
}
</style>
