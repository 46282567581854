<template>
	<div>
		<div class="p-center">
			<div class="foot-wh">
				<div class="dp-fl jc-sb foot-css">
					<div v-for="(item, index) in bottmolist" :key="index">
						<p class="fs-24">{{ item.title }}</p>
						<div v-if="item.children">
							<p
								v-for="(items, indexs) in item.children"
								:key="indexs"
								class="fs-14"
							>
								{{ items.title }}
							</p>
						</div>
					</div>
				</div>
				<div class="foot-css foot-bottom dp-fl jc-sb">
					<div class="wc-div">
						<img
							class="wb-80"
							src="../assets/images/ind-img14.png"
							alt=""
						/>
						<p class="ba-css">
							Copyright © 2018山东新丞华展览有限公司 版权所有
							鲁ICP备10037836号-17
						</p>
					</div>
					<div class="ta-rt">
						<p class="ml-2b">丞华微信公众号</p>
						<img
							class="wc-css"
							src="../assets/images/ind-img18.png"
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="m-center"></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			bottmolist: [
				{
					title: '公司',
					children: [
						{ title: '公司概述' },
						{ title: '企业文化' },
						{ title: '企业荣誉' },
						{ title: '我们的服务' },
						{ title: '品牌展览及会议' },
						{ title: '丞华大事记' },
					],
				},
				{
					title: '新闻',
					children: [
						{ title: '公司动态' },
						{ title: '展会动态' },
						{ title: '行业资讯' },
						{ title: '媒体聚焦' },
						{ title: '丞华｜每日一语' },
					],
				},
				{
					title: '展会',
					children: [
						{ title: '近期展会' },
						{ title: '近期会议' },
						{ title: '2024项目计划' },
					],
				},
				{
					title: '服务',
					children: [{ title: '展览工程' }, { title: '商业情报' }],
				},
				{ title: '网上展厅' },
				{
					title: '商旅服务',
					children: [{ title: '旅游景点' }, { title: '交通指南' }],
				},
				{
					title: '联系我们',
					children: [
						{ title: '工作机会' },
						{ title: '合伙人众创合作平台' },
						{ title: '联系我们' },
					],
				},
			],
		}
	},
	mounted() {},
	methods: {},
}
</script>

<style scoped lang="less">
.foot-wh {
	width: 100%;
	height: 515px;
	background: rgb(34, 34, 34);
}
.foot-css {
	padding: 2% 0;
	margin: 0 20%;
	color: #fff;
	border-bottom: 1px solid #2f2f2f;
}
.fs-24 {
	font-size: 16px;
	margin-bottom: 20px;
}
.fs-14 {
	font-size: 14px;
	color: rgb(181, 181, 181);
	margin: 5px 0;
}
.foot-bottom {
	border-bottom: 0 solid #000;
}
.wb-80 {
	width: 80%;
}
.ba-css {
	font-size: 14px;
	color: rgb(75, 75, 75);
}
.wc-css {
	width: 23%;
	vertical-align: middle;
}
.ta-rt {
	display: flex;
	flex-direction: column; /* 设置为垂直方向布局 */
	align-items: flex-end;
	// justify-content: flex-end; /* 垂直方向上底部对齐 */
}
.ml-2b {
	font-size: 14px;
}
</style>
